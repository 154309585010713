* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.demodiv {
  margin: 100px 0;
}
.card_item {
  position: relative;
}

.blogtop_btn {
  border: none !important;
  position: absolute;
  top: 20px;
  z-index: 1;
  display: flex;
  gap: 11px;
  left: 24px;
}
.blogtopbtn_btn a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #000909;
}
.blogtopbtn_btn {
  background: #ffffff;
  padding: 6px 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 1px #eee;
}
.Cards_row {
  gap: 29px;
  justify-content: flex-start !important;
}

.card_item > div {
  border-radius: 32px;
  box-shadow: none;
  border: 0.811986px solid rgba(0, 0, 0, 0.2);
}

.card_item h3 {
  font-size: 1rem;
  color: #000909;
  /* margin-bottom: 16px; */
  height: 2.3rem;
  line-height: 1.2;
  font-weight: 600;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
}
.cardimg1 {
  height: 150px;
  object-fit: cover;
  overflow: hidden;
}

.cardimg1 img {
  scale: 1.2;
}

.card_item p {
  font-size: 14px;
  color: #666666;
}
.card_item .MuiCardContent-root {
  /* padding: 27px 20px 24px 20px; */
  padding: 1rem;
  text-align: left;
}
.card_item img {
  height: auto;
}
.date_addtolist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 50px; */
  width: 100%;
  padding: 0 1rem 0.5rem;
}
.addtolist button {
  color: #19357c !important;
}
.res_crd_btn {
  font-size: 10px !important;
}
/* .addtolist{
    font-size: 18px;
    color: #19357C;
    font-weight: 700;
    display: flex;
    align-items: center;
} */
.date_addtolist .date {
  font-size: 14px;
  color: #666666;
}
.cardImage {
  /* height: 200px !important; */
  height: 150px !important;
  width: 100% !important;
  border-bottom: 1px solid #ccc;
}
.playbutton {
    position: absolute;
    z-index: 2;
    top: 8%;
    left: 0;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
}

.playbutton svg {
  width: 60px;
  height: 60px;
  color: #ffffff;
}
.cardimg {
  position: relative;
}

.sc_vid_ovl {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
