.vid-head-sec{
    z-index: 9;
}
.vid-head-btn{
    z-index: 9;
    display: flex;
    gap: .5rem;
    .cst-btn{
        &.contained{
            background-color: #000 !important;
        }
        &.outlined{
            border-color: #000 !important;
            color: #000 !important;
        }
    }
}

.vid-head-sec {
    
    // .vid-head-search{
    //     max-width: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     input{
    //         max-width: 300px;
    //     }
    // }
}

$primary: #fcdfd4;
$secondary: #d3dcf8;

.video-list li:nth-child(odd){
    background-color: #fff;

}
.video-left{
    background-color: $secondary;
    padding: 1rem 1.4rem;
    height: 59vh;
    overflow: auto;
    border-radius: 10px;
    ul{
        li{
            background-color: transparent !important;
            border-bottom-width: 2px !important;
            border-bottom-color: #b3c2f0 !important;
            &.activeListCls{
                background-color: darken($secondary, 30%) !important;
                color: #fff !important;
            }
            &:first-child{
                // border-radius: 10px 10px 0px 0px;
            }
            &:last-child{
                // border-radius: 0px 0px 10px 10px;
            }
            .vid-icon{
                display: flex;
                align-items: center;
            }
            .vid-date{
                width: 5rem;
                font-size: .8rem;
            }
        }
    }
}
.rdrMonth {
    width: 16.9rem;
}

.rdrDayPassive {
    pointer-events: unset !important;
}
.video-right-1{
    // background-color: $primary;
    padding: 1rem 1.4rem;
    height: 59vh;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px #eeeeee;
}
.btn_contained{
    background-color: #fff !important;
    color: #333 !important;
    font-weight: 600 !important;
}
.btn_outlined{
    border-color: #fff !important;
    color: #333 !important;
    font-weight: 600 !important;
}
.video-left span.rdrMonthAndYearPickers {
    display: block !important;
}
.nT{
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 0;
    }
}

.tabs-container{
    ul{
        display: flex;
        list-style: none;
        column-gap: 0.5rem;
        padding: 0;
        margin-bottom: 0.5rem;
        flex-wrap: wrap;
        li{
            background: #d7dadc;
            padding: 0.2rem 0.6rem;
            margin-top: 0.5rem;
            font-size: 0.9rem;
            font-weight: 500;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 600px) {
    .video-left{
        height:auto !important
    }
}