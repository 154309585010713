.vid-head-btn{
    z-index: 9;
    display: flex;
    gap: .5rem;
    .cst-btn{
        &.contained{
            background-color: #000 !important;
        }
        &.outlined{
            border-color: #000 !important;
            color: #000 !important;
        }
    }
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    color: black;
    background-color: white;
    border: 1px solid black;
    font-size: 11px;
}
.vid-head-sec {
    
    // .vid-head-search{
    //     max-width: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     input{
    //         max-width: 300px;
    //     }
    // }
}

$primary: #fcdfd4;
$secondary: #d3dcf8;

.video-list li:nth-child(odd){
    background-color: #fff;

}
.video-left{
    background-color: $secondary;
    padding: 1rem 1.4rem;
    height: 59vh;
    overflow: auto;
    border-radius: 10px;
    ul{
        li{
            background-color: transparent !important;
            border-bottom-width: 2px !important;
            border-bottom-color: #b3c2f0 !important;
            &.activeListCls{
                background-color: darken($secondary, 30%) !important;
                color: #fff !important;
            }
            &:first-child{
                // border-radius: 10px 10px 0px 0px;
            }
            &:last-child{
                // border-radius: 0px 0px 10px 10px;
            }
            .vid-icon{
                display: flex;
                align-items: center;
            }
            .vid-date{
                width: 5rem;
                font-size: .8rem;
            }
        }
    }
}
.video-right{
    background-color: #fff;
    padding: 1rem 1.4rem;
    height: 59vh;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px #eeeeee;
}
.video-left span.rdrMonthAndYearPickers {
    display: block !important;
}