@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700;800;900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
    --base-font: "Poppins", sans-serif;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif !important;
}

.mainLogo {
    /* background: #fff; */
    background: transparent;
    margin-right: 1rem !important;
    padding: 0 0.5rem;
}

.cInput {
    width: 100%;
}

.cInput input {
    /* padding: 0.3rem 0.5rem; */
    padding: 8.2px 0.5rem;
    font-size: 0.8rem;
}

.cInput input::placeholder {
    color: rgb(126, 126, 126);
}

.sidebar-label {
    font-size: 0.7rem;
    font-weight: 500;
}

.accordionBorder {
    border: 1px solid #ffa867;
    box-shadow: unset !important;
}

label#my-id {
    display: flex;
    flex-wrap: wrap;
}

label#my-id span {
    margin: 2px;
}

.carouselContainer {
    display: flex;
    align-items: center;
}

.companyThumb {
    margin: 10px 5px;
}

.companyContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.topCountLabel {
    color: white;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #a3a3a3;
    font-size: 3.7rem !important;
    line-height: 1 !important;
    font-family: fantasy !important;
    margin-left: 0.5rem !important;
}

.companyThumb img {
    object-fit: contain;
}

.headerContainerFront {
    /* background-image: url(https://omkaradata.in/vdr_old/public/images/banner-list.jpg); */
    background: #ffefd9;
    height: 20vh;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    min-height: 150px;
}

.headerContainerFront::before {
    min-height: 150px;
    content: "";
    background: #b3b3b312;
    position: absolute;
    width: 100%;
    height: 20vh;
    display: block;
}

.bodyContainer {
    margin: 1rem 0.8rem;
    max-width: 1200px;
    margin: 0 auto;
}

.headerContainerFront::after {
    content: "";
    z-index: 1;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 4rem;
    background: rgb(255 255 255);
    background: linear-gradient( 180deg, rgb(255 255 255) 0%, rgb(255 255 255 / 0%) 0%, rgb(255 255 255) 100%);
}

html {
    overflow: visible !important;
}

.MuiCardMedia-media {
    object-fit: contain !important;
    width: 90% !important;
    margin: 0 auto;
}

.priceRow {
    display: flex;
    align-items: center;
}

canvas.editor-canvas {
    width: 200px !important;
    height: 200px !important;
}

.detail-header {
    /* min-height: 12rem; */
    /* height: 29vh; */
    /* height: 45vh; */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: unset !important;
}

.categoryContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleContainer {
    display: flex;
    align-items: center;
}

.titleContainer>h4 {
    margin-right: 0.7rem;
    /* background: red; */
}

.logoContainer {
    position: relative;
    background: #fff;
}

.logoContainer div {
    position: absolute;
    right: 0;
    bottom: -2.5rem;
    background: #fff;
    width: 160px;
    height: 160px;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer div img {
    width: 100%;
}

.ResearchBody img {
    max-width: 100%;
}

.ResearchContainer {
    z-index: 2;
    position: sticky;
    top: 0;
    padding: 0.5rem;
    background-color: #fff;
}


/* .active {
  border-bottom: 3px solid #f37b21;
} */

.tabItem {
    margin: 0 0.2rem;
}

.box-container .list-container {
    /* height: 600px; */
    /* height: 300px; */
    overflow: auto;
}

.box-container {
    border: 1px solid #ddd;
    padding: 0.5rem 0.5rem;
    border-radius: 0.3rem;
}

::-webkit-scrollbar {
    height: 10px;
    width: 4px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
    border-radius: 15px;
    background-color: #ffbc8a;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #fff;
}

.cstTable th {
    padding: 0 0.5rem;
    font-size: 0.7rem;
}

.cstTable table td {
    font-size: 0.7rem;
    padding: 0.5rem;
}

.DataLink>div {
    z-index: 2;
}

.DataLink>div button:hover {
    background-color: #df711d;
}

.mainLogo img {
    width: 100%;
}

.mainLogo {
    width: 11rem;
}

.active button {
    background: #f37b21;
    color: #fff;
    border-color: #d56b1b;
}

#back-to-top-anchor {
    min-height: 0;
}

img.mediaItem {
    width: 100%;
}

.mediaItemBox img {
    max-height: 200px;
}

.mediaItemBox {
    height: 200px;
    margin: 0.5rem 0;
    background: black;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.external-buttons .swiper-button-prev,
.external-buttons.swiper-container-rtl .swiper-button-next {
    left: -16px;
    right: auto;
}

.external-buttons .swiper-button-next,
.external-buttons.swiper-container-rtl .swiper-button-prev {
    right: -16px;
    left: auto;
}

.carousel-container {
    padding: 0 0.8rem;
    overflow: hidden;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 1.4rem;
    font-weight: 900;
}

.external-buttons {
    overflow: visible;
}

.btn_active:hover {
    color: "#fff" !important;
    background-color: #d76d1e !important;
}

.btn_active {
    background-color: #f37b21 !important;
    border-color: #d76d1e !important;
    color: #fff !important;
}

.mainMenu a li {
    text-align: center !important;
    margin: 0 auto;
    justify-content: center;
}

.mainMenu a {
    color: #000 !important;
    text-decoration: none;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.react-pdf__Page {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.page-navigator {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background: #f37b21;
    color: #fff;
    position: fixed;
    width: 100%;
    z-index: 11;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
}

.box-container .list-container .docContainer:hover a h6 {
    color: #381be7 !important;
}

.sortListContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.sortListContainer ul {
    border: 1px solid #ccc;
    border-radius: 0.2rem;
}

.borderRight {
    border-right: 2px solid rgba(224, 224, 224, 1);
}

.dotedText {
    display: -webkit-box;
    width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.recommendationChild {
    margin-left: 0.5rem;
    font-weight: 600;
}

ul {
    padding-left: 2rem;
}

.tabContainer {
    align-items: center;
}

.tabContainer span.MuiTabs-indicator {
    height: 0;
}

.tabItem.active button {
    background-color: #e4731d;
    color: #fff
}

.tabItem.active button:hover {
    background-color: #e4731d;
    color: #fff
}

.tabItem button {
    border-color: #f37b21;
    color: #f37b21;
}

@use postcss-nested;
@use postcss-cssnext;

/*imports*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Lobster');

/* variables */

:root {
    /* colors */
    --primary-color: #f37b22;
    --primary-color-dark: color(#f37b22 shade(12%));
    --primary-color-tint: color(#f37b22 tint(70%));
    --secondary-color: #222;
    --secodnary-color-dark: color(#222 shade(12%));
    --secondary-color-tint: color(#222 tint(70%));
    /* width */
    --form-width: 23rem;
}

.form {
    width: var(--form-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0px solid;
    padding: 1rem;
    box-shadow: 0px 0px 7px 0px #ccc;
    border-radius: 0.2rem;
}

.form_logo {
    font-family: 'Lobster', cursive;
    font-size: 2.5rem;
    margin-bottom: 8px;
}

.form_logo span {
    color: var(--primary-color);
}

.form_title {
    font-weight: bold;
    margin-bottom: 8px;
}

.form_title span {
    color: var(--primary-color);
}

.form_items {
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.mtb-05rem {
    margin: 0.5rem 0;
}

.form_button {
    width: 100%;
    height: 36px;
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    border-radius: 2px;
    color: white;
    background: var(--primary-color);
    box-shadow: 2px 2px 2px var(--primary-color-dark);
}

.form_button:focus {
    outline: none;
}

.form_button:active {
    outline: none;
    background: var(--primary-color-dark);
}

.form_other {
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}

.form_other a {
    color: var(--primary-color);
    text-decoration: none;
}

.itemGrid {
    border: 1px solid #f37b22;
    padding: 0.5rem;
    margin: 0.5rem;
    text-align: center;
    height: 10rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    min-width: 21%;
    border-radius: 3px;
}

.buttonContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

button.btn-theme {
    width: fit-content;
    margin: 0.3rem 0;
    background-color: #f37b22;
    text-transform: capitalize;
    font-weight: normal;
    color: #fff;
}

button.btn-theme:hover {
    background-color: #228441;
    color: #fff;
}

.itemGrid h2 {
    margin: 0 0 0.5rem;
}

.w-100 {
    width: 100% !important;
}

.text-errors {
    color: #ff0000;
}

.fontSize-07rem {
    font-size: 0.7rem !important;
}

.fontSize-12px {
    font-size: 12px !important;
}

.fontWeight-600 {
    font-weight: 500 !important;
}

.padding-03rem>input {
    padding: 0.3rem !important;
}

.showItem>div {
    padding-left: 0.5rem;
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem;
    font-size: 0.7rem;
}

.tableHeader tr th {
    background: #fff2e9;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.companyContainer:hover .demoData::after {
    /* content: ""; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.companyContainer:hover .demoData {
    display: flex;
    transition: 0.5s;
    transform: translateY(100%);
}


.demoData {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: baseline;
    display: none;
    flex-direction: column;
    width: fit-content;
    float: right;
}



.sidebarBtn {
    width: 100%;
    border: 1px solid #dbdbdb;
    margin: 0.5rem;
    border-radius: 5px;
}
.active .demoClass {
    background: #f37b21;
    color: #fff;
}


.active:hover .demoClass {
    color: #333;
}

header > div {
    background: #fff !important;
    z-index: 12;
}


table.calendar td, table.calendar th {
    padding: 0.3rem;
}
a.mobLink {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
}
.headerContainer {
    background: #fff !important;
    /* background: #FCDFD4 !important; */
    /* background: #D3DCF8 !important; */
    color: #333 !important;
}
.headerContainer a{
    color: #333 !important;
}



.fItem {
    background: #c4c4c4;
    border-radius: 0.3rem;
    color: #000;
    font-size: .7rem;
    font-weight: 800;
    margin: 0.1rem;
    min-height: 3.2rem;
    min-width: 4rem;
    padding: 0.55rem 0.7rem 0.4rem;
    position: relative;
    width: fit-content;
    z-index: 1;
}
.fLabel {
    font-weight: 400;
}
.fValue {
    display: flex;
    min-width: 2rem;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.fClose, .fRow {
    align-items: center;
    display: flex;
}
.fRow {
    flex-wrap: wrap;
}
.filterContainer {
    display: flex;
    justify-content: space-between;
}
.fValue>div {
    background: #fff;
    border: 1px solid #9b9b9b;
    border-radius: 0.3rem;
    margin: 0 0.1rem;
    padding: 0 0.2rem;
}
.fClose {
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 0;
    top: -0.3rem;
}


td.color1 {
    background: #ffc7c7  !important;
    /* color: #fff; */
}

td.color2 {
    background: #cdf3c6 !important;
    /* color: #fff; */
}

td.color3 {
    background: #daecff !important;
    /* color: #fff; */
}
.mobLogoContainer {
    display: none;
}

.table-td, .table-th {
    padding: 5px !important;
    line-height: 1.3;
}
.table-th {
    background: #fff3ea !important;
}
tr:hover .table-td {
    background: #fbfddb !important;
}

.homeTicker{
    background-color:#ffff72;
    padding:0.5rem 1rem;
    border-radius:5px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 1920px) {
    .video-left div:first-child > button {
        font-size: 1.05rem;
    }


    .headerContainerFront > p{
        font-size: 3rem !important;
    }
    
    .headerContainerFront .vid-head-btn button {
        font-size: 1rem;
        border-radius: 5px;
        padding: 0.2rem 1rem;
    }
    .headerContainerFront::before{
        height: auto;
    }


}

/* @media (min-width: 1920px) {
    .video-left div:first-child > button {
        font-size: 1.5rem;
    }
} */

@media (max-width: 480px) {
    
    .homeTicker{
        /* flex-direction: column; */
    }

    .logoContainer {
        display: none;
    }
    .mobLogoContainer {
        display: block;
    }
    .mobLogoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 4px -2px #000;
        border-radius: 10px;
        margin-bottom: 1.5rem;
    }
    .titleContainer>h4 {
        font-size: 1.7rem;
        margin-bottom: 0.5rem;
    }
    .categoryContainer {
        width: 100%;
        display: block;
    }
    .titleContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    .afterTitle > div {
        padding: 0;
        margin: 0;
    }
    
    .afterTitle {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0.5rem;
    }
    .pipeDivider {
        display: none;
    }
    .TopContainer {
        margin-top: 0 !important;
        padding: 0 !important;
    }
    .box-container .list-container button {
        font-size: 0.7rem;
    }
    .tabPanelParent > div {
        padding: 1.5rem 0;
    }
}


.cell_add_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
}
.tableCell {
    position: relative;
}

.demoNew {
    display: flex;
}

button#test-table-xls-button {
    background: none;
    border: 0;
}

.demo-data.MuiBox-root.css-0 {
    width: calc( 33% - 0.2rem);
    margin: 0.2rem;
}

.calendarFilters input {
    font-size: 0.7rem;
}

#test-table-xls-button-price {
    padding: 0.3rem 0.8rem;
    background: #1976d2;
    color: #ffffff;
    border: unset;
    border-radius: 5px;
    cursor: pointer;
}

.selectInputNew input {
    font-size: 12px;
}
.table-th-checkbox > span {
    margin: 0;
    padding: 0;
}
.table-th-checkbox {
    margin-right: 0rem !important ;
}
.table-pagination-top p.MuiTablePagination-displayedRows {
    display: none;
}
.search-input input {
    padding: 0.3rem 0.7rem;
    font-size: 0.9rem;
}
.newData > div {
    margin-left: 0;
}
.calendar-search {
    padding: 0 !important;
    width: 28%;
}
.search-input input {
    padding: 0.4rem 0.7rem;
    font-size: 0.7rem;
}

.custom-table thead {
    border-top: 1px solid #ddd;
}
.custom-table td, .custom-table th {
    border-right: 1px solid #ddd;
    padding: 5px!important;
    font-size: 12px!important;
    font-weight: 500;
}

.custom-table thead > tr:first-child > th {
    border-top: 1px solid #ddd !important;
}
.rdrDateDisplayWrapper, span.rdrMonthAndYearPickers {
    display: none;
}
.rdrMonthName {
    text-align: center;
}
.rdrMonthAndYearWrapper {
    position: absolute;
    top: -1rem;
    display: flex;
    width: 100%;
}
.rdrCalendarWrapper.rdrDateRangeWrapper {
    position: relative;
}


.data-room1 .MuiTabs-indicator {
    display: none;
}


.data-room1 .Mui-selected {
    background-color: #f37b22;
    border: unset;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
    color: #fff !important;
}


.forensic-table tr:hover {
    background: none !important;
}

.forensic-table thead tr th {
    background: #c7dbb0 !important;
    font-weight: 600;
}

.forensic-table thead > tr:first-child > th:first-child, .forensic-table tbody > tr > td:first-child {
    border-left: 1px solid #ddd;
}


.orangeBoxShadow {
    box-shadow: 0px 0px 4px 0px #f37b22;
    margin-bottom: 2rem;
    padding: 0.5rem;
    border-radius: 0.1rem;
    background-color: #fff;
}
.blankCell {
    height: 17px;
}
text {
    fill: #000;
}

.legend {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
}
.legend ul {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    list-style: none;
}
.legend ul li {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}
.legend ul li span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.cTabBtn {
    border: unset;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
    color: #000;
    padding: 0.7rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.cTabBtn.active {
    color: #fff;
    background-color: #f37b22;
}
.data-room-doc > *{
    width: calc(100% - 18px);
    word-break: break-word;
}

.segmentItem {
    border: 1px solid #f37b21;
    padding: 1rem;
    position: relative;
}

.segmentItemTitle > div{
    transform: rotate(-180deg);
}

.segmentItemTitle {
    position: absolute;
    left: 0;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    top: 0;
    font-size: 1.3rem !important;
    font-weight: bold !important;
    text-transform: uppercase;
    background: #f37b21;
    line-height: 1 !important;
    margin: 0 !important;
    padding: 0.7rem;
    color: #fff;
    transform-origin: top left;
}
.custom-table tr > *:first-child {
    border-left: 1px solid #ddd;
}
.videoContainer{
    padding: 0 1rem;
}

.video-list li:not(:last-child) {
    border-bottom: 1px solid #ccc;
}
.video-list li:nth-child(even) {
    background: whitesmoke;
}
.video-list li:hover {
    background: antiquewhite;
}

/* .activeListCls{
    background-color: #4386be !important;
    color: #fff !important;
} */
.ActiveVidMBtn{
    background-color: #4386be !important;
}





.card-box {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 2px 10px -4px #000;
    border-radius: 1rem;
    margin-bottom: 2rem;
}

.notification_list li:not(:last-child) {
    border-bottom: 1px solid #ccc;
}
.notification_list li {
    padding-top: .6rem;
    padding-bottom: .6rem;
}

.notification_list li:nth-child(even) {
    background: whitesmoke;
}
.notification_list li:hover {
    background: antiquewhite;
}
.notification_list {
    max-height: 500px;
    overflow: auto;
}
.notification-title {
    text-align: start;
}
.notification-title span {
    font-size: 0.8rem;
}
.aaaaaa fieldset {
    border-color: #000;
}
  
table tbody tr:nth-child(odd) td {
    background: #f0f4fb !important;
}
.vid-body-sec table tbody tr td, .vid-body-sec table thead tr th {
    background: transparent !important;
}