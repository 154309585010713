.vid-head-sec{
    z-index: 9;
}
.vid-head-btn{
    z-index: 9;
    display: flex;
    gap: .5rem;
    .cst-btn{
        &.contained{
            background-color: #000 !important;
        }
        &.outlined{
            border-color: #000 !important;
            color: #000 !important;
        }
    }
}

.vid-head-sec {
    
    // .vid-head-search{
    //     max-width: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     input{
    //         max-width: 300px;
    //     }
    // }
}

$primary: #fcdfd4;
$secondary: #d3dcf8;

.video-list li:nth-child(odd){
    background-color: #fff;

}
.video-left{
    background-color: $secondary;
    padding: 1rem 1.4rem;
    height: 59vh;
    overflow: auto;
    border-radius: 10px;
    ul{
        li{
            background-color: transparent !important;
            border-bottom-width: 2px !important;
            border-bottom-color: #b3c2f0 !important;
            &.activeListCls{
                background-color: darken($secondary, 30%) !important;
                color: #fff !important;
            }
            &:first-child{
                // border-radius: 10px 10px 0px 0px;
            }
            &:last-child{
                // border-radius: 0px 0px 10px 10px;
            }
            .vid-icon{
                display: flex;
                align-items: center;
            }
            .vid-date{
                width: 5rem;
                font-size: .8rem;
            }
        }
    }
}
.video-right-p{
    background-color: $primary;
    padding: 1rem 1.4rem;
    height: 59vh;
    overflow: auto;
    border-radius: 10px;
}
.addToList{
    font-size: .8rem !important;
    font-weight: bold !important;
    color: #1C3994 !important;
    text-transform: capitalize !important;
}
.comment-box{
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    box-shadow: 0px 0px 2px 1px #eeeeee;
    .comment-author-cont{
        display: flex;
        align-items: center;
        column-gap: .5rem;
        .author-detail{
            display: flex;
            align-items: center;
            column-gap: .5rem;
            img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
            h3{
                font-size: .85rem;
            }
        }
    }
    .comment-description{
        margin-top: .5rem;
        line-height: 1;
        font-size: .9rem;
    }
}
.searchBox {
    fieldset{
        border-color: #000 !important;
    }
}