.box-head{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    column-gap: 1rem;
    .uDetail{
        ul{
            display: flex;
            padding: 0;
            li{
                list-style: none;
                border-right: 2px solid #ccc;
                padding: 0 0.5rem ;
                font-weight: 600;
                &:first-child{
                    padding-left: 0 ;
                }
                &:last-child{
                    border: 0;
                }
            }
        }
    }
}
.box-body{
    iframe{
        width: 100%;
        min-height: 450px;
    }
}
.listItem{
    h3{
        
    }
    &>div{
        font-size: 0.8rem;
        font-style: italic;
        font-weight: 500;
    }
}
.circle-card{
    font-size: 14px;
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    &--img{
        border: 1px solid orange;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: .5rem;
        width: 180px;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            
        }
    }
}
.category-sec{
    margin-top: .5rem;
    ul{
        li{
            background: #0d347a;
            color: #fff;
            padding: 0 0.6rem !important;
            border-radius: 3px;
        }
    }
}
.listItem-head {
    position: sticky !important;
    top: -1rem;
    width: 100% !important;
    background: #133c59;
    z-index: 99;
    padding: 1rem;
    color: #fff;
}

.rdrMonthAndYearWrapper {
    position: unset !important;
}
.rdrMonths.rdrMonthsVertical button.rdrDay.rdrDayPassive.rdrDayWeekend.rdrDayStartOfWeek {
    pointer-events: revert;
}