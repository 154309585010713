
$grn: #03CF3C;

.compnayDetailPage {
    background: #f0f4fb;
    .logoContainer div {
        width: 140px;
        height: 140px;
        padding: 0.5rem;
        box-shadow: 0px -5px 13px rgb(0 0 0 / 8%);
        border-radius: 1rem;
    }
}
.sc-main_box {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 13px rgba(0,0,0,.06);
    margin-bottom: 0;
    margin-top: 0;
    padding: 1rem;
}
.detailContainer table thead > tr > * {
    background: #c7dbb0 !important;
}
.price-section{
    span{
        font-size: 20px;
        &:first-child{
            font-weight: 500;
        }
        &:last-child{
            color: $grn;
        }
    }
}
.l-cd{

    .comp_note_table{
        margin-top: 1rem;
        // border: 1.5px solid #d0dbebcc;
        border-radius: 10px;
        padding: 1rem;
        width: 100%;
        &>div {
            display: flex;
            width: 100%;
            gap: 0.7rem;
            &>div {
                width: calc(100% / 5);
            }
        }
        table{
            width: 100%;
            border-collapse: collapse;
            tbody{
                font-size: 14px;
                td{
                    padding: 0.8rem 1.2rem;
                    background: transparent !important;
                    font-size: 14px;
                    &:last-child {
                        border-radius: 0 5px 5px 0;
                    }
                    &:first-child {
                        border-radius: 5px 0 0 5px;
                    }
                }
            }
        }
        .plItem{
            border: 0 solid #d0dbebcc;
        }
    }
}
.sc_box{
    .sc-main_box {
        margin-top: 0.5rem;
    }
}