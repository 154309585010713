.card-box{
    min-height: 200px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text{
        font-size: 1.2rem;
    }
    cursor: pointer;
    transition: .3s ease-in-out;
    &:hover{
        box-shadow: 0 2px 10px -2px #3a3a3a;
    }
}
a.res-link {
    text-decoration: none;
    color: black;
    text{
        font-size: 2rem;
        font-weight: 600;
    }
}
