@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import "https://code.highcharts.com/css/stocktools/gui.css";
@import "https://code.highcharts.com/css/annotations/popup.css";


// Start Color  

:root {
    --primary-color: #0521f5;
    // $text-color: #262833;
}


// $txtNewClr: #0C374D;
// $txtNewClr: #0C374D;
// $txtNewClr: #006aff;
// $txtNewClr: #645df9;
// $txtNewClr: #598EF8;
// $txtNewClr: #6771dc;
    $txtNewClr: var(--primary-color);
    // $primary: #598EF8;
    $primary: $txtNewClr;
    $primary_hover: #705ac0;
    $primary-light: #f0f4fb;
    // $primary: #FCDFD4;
    // $primary_hover: #F37B22;
    // $primary-light: #F5EEE8;
    // $primary: #F37B22;
    // $primary_hover: #db6f1e;
    // $primary-light: #F5EEE8;
    $grn: #03CF3C;
// END Color 
$fs14:14px;
$fs20:20px;

:export {
    primaryColor: $txtNewClr
}


.defaultButton {
    background-color: $primary !important;
}
.defaultButtonTxt {
    color: $primary !important;
}
.text-primary{
    color:$primary
}
html{
    scroll-behavior: unset !important;
}

.sc_detail{
    font-family: Inter !important;
    .ql-editor.ql-blank {
        height: 200px;
    }
    .cst-box-body {
        // padding-bottom: 24px;
        margin-bottom: 1.7rem
    }
    .cst-box-body ul {
        // padding-bottom: 24px;
    } 
    
}

.ql-container.ql-snow {
    border: 1px solid #ccc !important;
    border-top: 0 !important;
}   

main{
    background-color: $primary-light;
    // background-color: #f5eee8;
    min-height: 100vh;
}
.about-company{
    // margin-bottom: 3rem;
.comp-head{
    display: flex;
    align-items: center;
    column-gap: 14px;
}
.price-detail{
    .price-section{
        span{
            font-size: $fs20;
            &:first-child{
                font-weight: 500;
            }
            &:last-child{
                color: $grn;
            }
        }
    }
    &>span{
        opacity: .5;
        font-size: $fs14;
    }
}
    h2{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        display: flex;
        align-items: center;
    }
    .desc{
        // margin-top: 1rem;
        font-size: $fs14;
    }
}
.sc-main_box{
    background: #FFFFFF;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    padding: 1.5rem 2rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    border-radius: 5px;
    padding: .5rem 1rem;
    border: unset;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.primary-btn{
    background: $primary;
    color: #fff;
    &:hover{
        background: $primary_hover;
        color: #fff;
    }
}

.primary-btn-outline{
    background: #FFF;
    color: $primary;
    border: 1px solid #e5e5e5;
}

.secondary-btn-outline{
    background: #FFF;
    color: #999999;
    border: 1px solid #999999;
    &:hover{
        background: #999999;
        color: #fff;
    }
}
.btn-group{
    display: flex;
    align-items: center;
    button{
        border-radius: unset;
        border: 1px solid #cccccc;
        background-color: #fff;
        color: #606F7B;
        &:hover{
            background-color: #e5e5e5;
        }
        &:first-child{
            border-radius: 10px 0 0 10px;
        }
        &:last-child{
            border-radius: 0 10px 10px 0;
        }
        &.active{
            background-color: $primary-light;
            color: $primary;
            border-left-color: $primary-light;
            border-top-color: $primary-light;
            border-bottom-color: $primary-light;
        }
    }
}
.box-body-sec{
    table{
        border-collapse: collapse;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06);
        border-radius: 15px;
        border: 0;
        border: 1px solid rgba(4, 4, 21, 0.08);
        overflow: hidden;
        thead{
            th, td{
                padding: 0.5rem .6rem;
                font-weight: 600;
                color: #2D2D35;
                font-size: 13px;
                text-align: right;
                &:first-child{
                    text-align: left;
                }
            }
        }
        tbody{
            tr{
                &:nth-child(odd){
                    background-color: $primary-light;
                }
                &.tr-odd{
                    background-color: $primary-light;
                }
                &.tr-even{
                    background-color: #fff;
                }
            }
            td{
                padding: 0.5rem .6rem;
                font-size: 13px;
                color: #606F7B;
                text-align: right;
                &:first-child{
                    text-align: left;
                    font-weight: revert;
                    color: #000;
                    max-width: 10rem;
                }
                .td-icon{
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
    }
}
.box-head-sec{
    display: flex;
    // flex-direction: column;
    align-items: center;   
    justify-content: space-between;
    margin-bottom: 1rem;
    h3{
        font-weight: 500;
        font-size: 28px;
        color: #000000;
    }
    &>div{
        display: flex;
        align-items: center;
        column-gap: 1.1rem;
    } 
}
.video-card{
    background: #F5EEE8;
    border-radius: 7px;
    padding: 0.8rem;
    h5{
        font-weight: 600;
        font-size: 12px;
        color: #000000;
        margin-top: .5rem;
    }
    .vid-thumb{
        position: relative;
        border-radius: 7px;
        overflow: hidden;
        img{
            width: 100%;
        }
        &::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
            overflow: hidden;
        }
    }
    .play-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        img{
            width: 45px;
            height: 45px;
        }
    }
}
.interim-btn-sec{
    margin-bottom:14px;
    display:flex;
    // justify-content: flex-start;
    &>div{
        &:first-child{
            width: 33%;
        }
        &:last-child{
            width: calc(100% - 33%);
            display: flex;
            justify-content: end;
        }
    }
}
.c-head-sec0 {
    min-height: 120px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
}
.c-head-sec1 {
    min-height: 60px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
}
.final-out-btn{
    display: flex;
    gap: 0.5rem;
    padding: 0;
    flex-wrap: wrap;
    li{
        list-style: none;
        button{
            font-size: 12px;
        }
    }
}
.cst-box {
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 5px;
}
.ct-row {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ct-btn {
        color: $primary;
        border-color: $primary;
        font-size: 11px;
        &:hover{
            border-color: $primary;
        }
    }
    .ct-btn-group{
        display: flex;
        gap:.75rem;
        align-content: space-between;
    }
}
.cst-box-body {
    height: 250px;
    overflow: auto;
}
.site-nav-container{
    // position: sticky;
    // z-index: 9;
    // top: 4rem;
    position: fixed;
    z-index: 999;
    top: 5.5rem;
    left: 0;
    width: 100%;
    background: #F8F8FC;
    // padding: 1rem;
    padding: 1rem 1rem 0.4rem;
    margin: 0;
    margin-top: -1.5rem;
    font-size: .85rem;
    font-weight: 500;
    box-shadow: 0px 0px 5px 0px #ccc;
    a{
        cursor: pointer;
        border: 0;
        border-radius: 0;
        padding-bottom: .4rem;
        &.active{
            color:$primary;
            border-bottom: 2px solid $primary;
            padding-bottom: 0.4rem;
        }
    }
}

$breakpoint-tablet: 1366px;
@media (max-width: $breakpoint-tablet) {
    .box-body-sec{
        thead{
            th, td{
                font-size: 12px !important;
            }
        }
        tbody{
            th, td{
                font-size: 11px !important;
            }
        }
    }
    .interim-btn-sec{
        width: 100%;
        div{
            &:first-child{
                width: 50%;
            }
            &:last-child{
                width: calc(100% - 50%);
                justify-content: flex-end;
                .btn-group{
                    width: 100%;
                }
            }
        }
    }
}



// .site-nav {
//     display: flex;
//     font-size: .75rem;
//     column-gap: .5rem;
//     .a{
//         cursor: pointer;
//         border: 0;
//         border-radius: 0;
//         padding-bottom: .4rem;
//         text-decoration: none;
//         border: 0;
//         color: #000000DE;
//         &.active{
//             color:$primary;
//             // border-bottom: 2px solid $primary;
//             padding-bottom: 0.4rem;
//         }
//     }
// }
.mainLogoContainer{
    p{
        color: $primary !important;
        font-size: 13px;
        font-weight: 600;
    }
    a{
        color: $primary !important;
        font-size: 13px;
        font-weight: 600;
    }
}
// .topMenu{
//     p{
//       color: $primary;  
//     }
//     a{
//         color: $primary;  
        
//     }
// }
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:horizontal {
    border-radius: 15px;
    background-color: $primary;
}
.sc_arrow_color{
    color: $primary !important;
}

// ---------------------Aakash Ralhan -----------
.cst-box {
    // padding: 0.5rem;
    // padding: 1rem 1.75rem;
    padding: 1rem;
    // height: 100%;
    position: relative;
}
.footer-section {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
}
.cst-box-body svg {
    width: 18px;
}
.final-out-btn li button {
    font-size: 13px;
    padding: 4px 8px;
}
.final-out-btn {
    gap: 4px;
}
.c-head-sec0 div:nth-child(2) {
    font-size: 12px;
}
.final-out-btn + div {
    margin-top: 19px;
}
.ct-row p {
    font-size: 14px;
}
.c-head-sec0 input {
    padding: 3px 0px !important;
}
.c-head-sec0 label {
    top: -3px;
}
.c-head-sec0 svg {
    width: 18px;
}
svg[data-testid=DeleteIcon] {
    fill: #d46363;
}

.ct-row .ct-btn {
    height: fit-content;
}
// .cst-box-body {
//     min-height: 500px !important;
//     height: 500px !important;
// }
@media screen and (max-width:1440px){
    .final-out-btn li button {
        font-size: 8px;
        padding: 2px 5px;
        min-width: 2rem;
        justify-content: center;
    }
}
// ---------------------Aakash Ralhan -----------

.h-auto{
    height: auto !important;
}
.pl_footer{
    margin-top: 1rem;
    table{
        width: 100%;
        tbody{
            font-size: 14px;
            color: #606f7b;
            td{
                padding: .2rem;
            }
        }
    }
    .plItem{
        border: 1.5px solid #d0dbebcc;
        border-radius: .5rem;
        padding: 1rem;
        height: 100%;
    }
}
.comp_note_table{
    border: 1.5px solid #d0dbebcc;
    border-radius: 10px;
    width: 100%;
    &>div {
        display: flex;
        width: 100%;
        &>div {
            width: calc(100% / 5);
        }
    }
}
.comp_note{
    margin-top: 1rem;
    table{
        width: 100%;
        border-collapse: collapse;
        tbody{
            font-size: 14px;
            color: #606f7b;
            td{
                padding: 0.8rem 1.2rem;
                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
                &:first-child {
                    border-radius: 5px 0 0 5px;
                }
            }
        }
    }
    .plItem{
        border: 0 solid #d0dbebcc;
        // height: 100a;
    }
}

// .comp_note table tbody tr td:last-child {
//     border-radius: 0 5px 5px 0;
// }

// .comp_note table tbody tr td:first-child {
//     border-radius: 5px 0 0 5px;
// }
.extraHead {
    display: flex;
    gap: 0.8rem;
    margin-top: .5rem;
    a {
        color: $primary;
        text-decoration: none;
    }
}



.cst_disabled {
    opacity: 0.5;
    background: #efefef;
    cursor: not-allowed;
    &:hover {
        background: #efefef;
        color: #999999;
    }
}

.dft-text{
    font-weight: 400 !important;
    font-size: 14px !important;
    color: $txtNewClr !important;
}

.text-color1{
    color: $txtNewClr;
}

.client-docs {
    .ct-row .ct-btn-group {
        gap: 0.5rem !important;
        padding-right: 1rem;
        .ct-btn {
                font-size: 12px !important;
                padding: 3px 8px !important;
                min-width: auto;
                border-radius: 8px;
                color: $txtNewClr;
                border-color: $txtNewClr !important;
            }
            .ct-btn-disable {
                background: #e1e1e1;
                color: #a7a7a7 !important;
                border-color: #a7a7a7 !important;
            }
    
        p{
            font-size: unset;
        }
    }
}

.client-docs {
    .ct-row{
        p{
            font-size: unset;
            color: #606f7bcc;
        }
    }
}

.parentSec {
    display: flex;
    gap: 2rem;
    .childSec:first-child {
        min-width: 400px;
    }
    .childSec:nth-child(2) {
        min-width: 300px;
    }
    .childSec:nth-child(3) {
        min-width: 330px;
    }
}


img.svc_pdf_img {
    // width: 30px;
}
img.pdf_icon {
    width: 1rem;
}
.color-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // row-gap: .5rem;
    &>div{
        width: 5rem;
        height: 5rem;
        border-radius: 50px;
        overflow: hidden;
        cursor: pointer;
        // margin-top: .5rem;
        margin-bottom: .5rem;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &>*{
            cursor: pointer;
        }
        &:last-child{
            input{
                position: absolute;
                opacity: 0;
            }
        }
    }
}
.btn_customize{
    background-color: $primary !important;
    &:hover{
        background-color: $primary_hover !important;
    }
}




@media screen and (min-width:1191px) and (max-width:1720px){
    .color-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        &>div{
            width: 4rem;
            height: 4rem;
        }
    }
}

@media screen and (max-width:1024px){
    .parentSec {
        gap: 1.5rem;
    }
}



// ---------------------Aakash Ralhan -----------
.cst-box {
    // padding: 0.5rem;
    // padding: 1rem 1.75rem;
    padding: 1rem;
    // height: 100%;
    position: relative;
}
.footer-section {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
}
.cst-box-body svg {
    width: 18px;
}
.final-out-btn li button {
    font-size: 13px;
    padding: 4px 8px;
}
.final-out-btn {
    gap: 4px;
}
.c-head-sec0 div:nth-child(2) {
    font-size: 12px;
}
.final-out-btn + div {
    margin-top: 19px;
}
.ct-row p {
    font-size: 14px;
}
.c-head-sec0 input {
    padding: 3px 0px !important;
}
.c-head-sec0 label {
    top: -3px;
}
.c-head-sec0 svg {
    width: 18px;
}
svg[data-testid=DeleteIcon] {
    fill: #d46363;
}

.ct-row .ct-btn {
    height: fit-content;
}
// .cst-box-body {
//     min-height: 500px !important;
//     height: 500px !important;
// }
@media screen and (max-width:1440px){
    .final-out-btn li button {
        font-size: 8px;
        padding: 2px 5px;
        min-width: 2rem;
        justify-content: center;
    }
}
// ---------------------Aakash Ralhan -----------

.h-auto{
    height: auto !important;
}
.pl_footer{
    margin-top: 1rem;
    table{
        width: 100%;
        tbody{
            font-size: 14px;
            color: #606f7b;
            td{
                padding: .2rem;
            }
        }
    }
    .plItem{
        border: 1.5px solid #d0dbebcc;
        border-radius: .5rem;
        padding: 1rem;
        height: 100%;
    }
}
.comp_note_table{
    border: 1.5px solid #d0dbebcc;
    border-radius: 10px;
    width: 100%;
    &>div {
        display: flex;
        width: 100%;
        &>div {
            width: calc(100% / 5);
        }
    }
}
.comp_note{
    margin-top: 1rem;
    table{
        width: 100%;
        border-collapse: collapse;
        tbody{
            font-size: 14px;
            color: #606f7b;
            td{
                padding: 0.8rem 1.2rem;
                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
                &:first-child {
                    border-radius: 5px 0 0 5px;
                }
            }
        }
    }
    .plItem{
        border: 0 solid #d0dbebcc;
        // height: 100a;
    }
}

// .comp_note table tbody tr td:last-child {
//     border-radius: 0 5px 5px 0;
// }

// .comp_note table tbody tr td:first-child {
//     border-radius: 5px 0 0 5px;
// }
.extraHead {
    display: flex;
    gap: 0.8rem;
    margin-top: .5rem;
    a {
        color: $primary;
        text-decoration: none;
    }
}



.cst_disabled {
    opacity: 0.5;
    background: #efefef;
    cursor: not-allowed;
    &:hover {
        background: #efefef;
        color: #999999;
    }
}

.dft-text{
    font-weight: 400 !important;
    font-size: 14px !important;
    color: $txtNewClr !important;
}

.text-color1{
    color: $txtNewClr;
}

.client-docs {
    .ct-row .ct-btn-group {
        gap: 0.5rem !important;
        padding-right: 1rem;
        .ct-btn {
                font-size: 12px !important;
                padding: 3px 8px !important;
                min-width: auto;
                border-radius: 8px;
                color: $txtNewClr;
                border-color: $txtNewClr !important;
            }
            .ct-btn-disable {
                background: #e1e1e1;
                color: #a7a7a7 !important;
                border-color: #a7a7a7 !important;
            }
    
        p{
            font-size: unset;
        }
    }
}

.client-docs {
    .ct-row{
        p{
            font-size: unset;
            color: #606f7bcc;
        }
    }
}

.parentSec {
    display: flex;
    gap: 2rem;
    .childSec:first-child {
        min-width: 400px;
    }
    .childSec:nth-child(2) {
        min-width: 300px;
    }
    .childSec:nth-child(3) {
        min-width: 330px;
    }
}

@media screen and (max-width:1024px){
    .parentSec {
        // display: flex;
        gap: 1.5rem;
        // .childSec:first-child {
        //     width: 400px;
        // }
        // .childSec:nth-child(2) {
        //     width: 300px;
        // }
        // .childSec:nth-child(3) {
        //     width: 330px;
        // }
    }
}

img.svc_pdf_img {
    // width: 30px;
}
img.pdf_icon {
    width: 1rem;
}



// ---------Start Aakash Ralhan -------------------------------
.box-body-sec {overflow-x: scroll;}
::-webkit-scrollbar:horizontal {height: 4px;}
.mainLogoContainer p, .mainLogoContainer a{font-size: 12px;white-space: nowrap;}


.mainLogoContainer p, .mainLogoContainer a{
    color: #6771dc!important;
}



@media (min-width:3000px) /*and (max-width:2500px) */{
    .mainLogoContainer p, .site-nav-container a, .mainLogoContainer a{
        font-size: 1.4rem;
    }
    .site-nav-container button {
        font-size: 24px;
    }
    .btn{
        font-size: 26px;
        padding: 19px 10px;
    }
    .comp_note table tbody{
        font-size: 16px;
    }
    .about-company .price-detail .price-section span {
        font-size: 2.2rem;
    }
    .about-company .desc {
        font-size: 1.5rem;
    }
    .price-detail > div:nth-child(2) p {
        font-size: 24px;
    }
    .price-detail > div:nth-child(1) span {
        font-size: 24px;
    }
    .extraHead {
        font-size: 18px;
    }
    .themecolor {
        font-size: 24px;
    }
    small {
        font-size: 16px;
    }
    .box-body-sec table tbody td, .box-body-sec table thead td{
        font-size: 1.5rem;
    }
    .box-head-sec h6 {
    font-size: 20px;
}
.valuation-box .cst-box > div:nth-child(1) p {
    font-size: 18px;
}
.valuation-box .cst-box .cst-box-body ul li h6 {
    font-size: 22px !important;
}
.valuation-box .cst-box .cst-box-body ul li p > div > div {
    font-size: 20px !important;
}
.valuation-box .cst-box .cst-box-body ul li img {
    width: 30px;
}
.final-out-btn li button {
    font-size: 18px;
    padding: 8px 14px;
}
.comp_note table tbody td {
    font-size: 20px;
}
.about-company h2 {
    font-size: 60px;
}
.extraHead a{
    font-size: 24px;
}
img.pdf_icon {
    width: 1.7rem;
}
.pl_footer table tbody td{
    font-size: 2rem;
}
#sc_box4 .plItem table thead td {
    font-size: 2rem;
    padding-bottom: 30px;
}
#sc_box4 .pl_footer > div > div {
    flex: 0 0 33%;
    max-width: none;
}
.srch_comybar > div > div > div > div > div > div{
    font-size: 24px;
}
table tbody td div svg{
    font-size: 3rem !important;
}
table tbody tr td:nth-child(1) div svg{
    width: 25px;
    height: 25px;
}
img.pdf_icon {
    width: 2.2rem;
}
.parentSec .childSec:nth-child(3){
    min-width: 470px;
}
.parentSec .childSec:nth-child(2) {
    min-width: 400px;
}
.parentSec .childSec:first-child {
    min-width: 600px;
}
}


@media (min-width: 1600px){
    .mainLogoContainer p, .mainLogoContainer a {
        font-size: 13px;
        white-space: nowrap;
    }
}






.compnayDetailPage {
    .parentSec {
        // &>div {flex: 0 0 30%; min-width: 0 !important;} 
    }
}

// .parentSec > div {flex: 0 0 30%;min-width: 0 !important;}

@media (max-width:1440px){
.site-nav {flex: 0 0 85%;display: flex; overflow: scroll;}
.site-nav::-webkit-scrollbar-thumb:horizontal {background-color: #838ddd !important; height: 2px;}
.site-nav-container a{white-space: nowrap;}
.site-nav-container {padding: 8px 15px;}
.site-nav + button {flex: 0 0 10%;}
.parentSec {flex-wrap: wrap;}

}

@media (max-width:1200px){
.comp_note table tbody td {padding: 10px 11px;}
.comp_note table tbody{font-size: 12px;}
.extraHead a{font-size: 14px;}
.about-company h2{font-size: 32px;}
.about-company .price-detail .price-section span {font-size: 18px;}
.themecolor label {font-size: 14px;}
.pl_footer .plItem table thead tr td {font-size: 14px;padding-bottom: 10px;}
.comp_note .plItem {padding: 13px 10px;overflow: scroll;}
}

@media (max-width:1024px){
    .srch_comybar > div > div > div > div {text-align: right;}
.srch_comybar > div > div > div > div > div{align-items: end; width: 80%;}
.srch_comybar > div > div > div > div > div > div{font-size: 14px;}
}

@media (max-width:991px){
.site-nav-container {padding: 8px 9px;}
.site-nav + button {flex: 0 0 14%;white-space: nowrap;}
.comp_note_table > div{flex-wrap: wrap;}
.comp_note_table > div > div {flex: 0 0 48%;}
.price-detail {gap: 5px !important ;}
.anupl_tabs  {flex: 0 0 32% !important ;max-width: 32% !important;}
.srch_comybar > div > div{gap: 0px !important;}
.srch_comybar > div > div > div > div {text-align: right;}
.srch_comybar > div > div > div > div > div{align-items: end; width: 60%;}
.srch_comybar > div > div > div > div > div > div{font-size: 12px;}
.srch_comybar img {width: 25px;}
}

@media (max-width:868px){
.srch_comybar > div > div > div > div > div {width: 49%;}
}
// ---------End Aakash Ralhan -------------------------------

// Start -------------------------------29 Sep 2023 ------------------------------------
:root {
    --button-primary: #2614F1;
    --button-clr-primary: #fff;
    --button-secondary: #F0F4FB;
    --button-clr-secondary: #2614F1;
    --button-third: #fff;
    --button-clr-third: #afafaf;
    --text-clr-primary: #000000;
    --newClr-primary : #2614F1;
    --clr-table-heighlight : #e0ecff; 
    --clr-table-hover : #c2d9fd; 
  }
  
  /*Start  Button Style */
  .btn-cst-primary{
    background-color: var(--button-primary) !important;
    color: var(--button-clr-primary) !important;
    box-shadow: none !important;
  }
  .btn-cst-primary:hover{
    background-color: var(--button-secondary) !important;
    color: var(--button-clr-secondary) !important;
  }
  
  .btn-cst-secondary{
    background-color: var(--button-secondary) !important;
    color: var(--button-clr-secondary) !important;
    border: none !important;
    box-shadow: none !important;
  }
  .btn-cst-secondary:hover{
    background-color: var(--button-primary) !important;
    color: var(--button-clr-primary) !important;
  }
  
  .btn-cst-third{
    background: var(--button-third) !important;
    color: var(--button-clr-third) !important;
    border-color: var(--button-clr-third) !important; 
  }
  
  
  .btn-cst-third:hover{
    background: var(--button-primary) !important;
    color: var(--button-clr-primary) !important;
    border-color: var(--button-primary) !important;
  }
  
  /*End  Button Style */
  table {
    background: #fff;
    border: 0;
    border: 1px solid rgba(4,4,21,.08) !important;
    border-collapse: collapse;
    // border-radius: 15px;
    box-shadow: 0 4px 13px rgba(0,0,0,.06);
    overflow: hidden;
    width: 100%;
  }
  table thead tr th {
    background-color: var(--clr-table-heighlight)!important;
    box-shadow: rgb(204, 204, 204) 0px 3px 3px;
  }
  
//   table tbody tr:nth-child(odd) td {
//     background: #f0f4fb !important;
//   }

  table tbody tr:nth-child(even) td {
    background: #fff !important;
  }
  .listPage {
    background: none !important;
    box-shadow: none !important;
}
.tabel-cst-filter{
    background-color: #fff;
    margin-bottom: 20px;
}
tr:hover .table-td {
    background: var(--clr-table-hover) !important;
}
.assignPageheader > div:nth-child(2) > div > div {
    flex: 0 0 26%;
}
.assignPageheader > div:nth-child(2) > div > div:last-child {
    flex: 0 0 10%;
}
.assignPageheader > div:nth-child(2) > div {
    align-items: center;
    justify-content: space-around;
}

@media (max-width: 1920px) {
    .assignPageheader > div:nth-child(2) > div > div {
        flex: 0 0 20%;
    }
}

@media (max-width: 1920px) {
    .assignPageheader > div:nth-child(2) > div > div {
        flex: 0 0 26%;
    }
}




// End ---------------------------------29 Sep 2023 ------------------------------------ 






.head-w-bg button, .head-w-bg svg {
    color: #000 !important;
    fill: #000;
}
.head-w-bg {
    background: #c7dbb0;
    padding: 7px 0.5rem 2px;
}

.sc-videoContainer{
    .video-left {
        height: 100vh;
    }
    .video-right-p{
        height: 100vh;
    }
}
#dataToCopy{
    font-size: 13px;
}
.watchList-sub-item {
    width: 100%;
    margin-right: 0;
}